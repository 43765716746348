.terms-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    margin-left: 250px;
    margin-right: 250px;
    padding: 30px;

}

.terms-heading {
    font-family: "Roboto";
    font-size: 22px;
    text-align: center;
}

.terms-heading1 {
    font-family: "Roboto";
    font-size: 22px;
    text-decoration: underline;
    margin-top: -5px;
    text-align: center;
}

.para {
    text-indent: 3px;
    margin: 2px;
    letter-spacing: 2px;
    text-align: justify;
    font-weight: 400;
    font-family: serif;
    margin-top: 5px;
    font-size: 20px;
}

.side-heading {
    display: flex;
    align-self: flex-start;
    margin-top: 25px !important;
}

.para {
    text-indent: 0px;
    margin: 2px;
    letter-spacing: 0px;
    text-align: justify;
    font-family: serif;
    margin-top: 20px;
    font-size: 20px;
}

.agreement {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    align-items: flex-start;


}

.registration {

    align-items: center;
    align-self: center;
    flex-wrap: wrap;


}

.number {
    margin-right: 2px;
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
}

.adress {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: flex-end;
    text-align: end;

}

.order-list {
    text-decoration: underline;
    margin-right: 20px;
    margin-bottom: 5px;
    margin-left: 20px;
    font-weight: bold;
    padding-left: 30px;
}