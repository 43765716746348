.policy-container {
    display: flex;
    flex-direction: column;

    align-items: center;
    align-self: center;
    margin-top: 100px !important;
    margin-left: 250px;
    margin-right: 250px;
    padding: 30px;
    margin-bottom: 70px;
}

.nav-container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin-left: 50px;
    height: 70px;

}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;


}

.navbar-images {
    transform: rotate(0deg);
    margin-top: 20px;
    width: 200px;
    background-color: white;
}

.policy-heading {
    margin-top: 0px;
    font-size: 29px;
}

.side-heading {
    display: flex;
    align-self: flex-start;
    margin-top: 25px !important;
    font-family: sans-serif;
}

.paragraph {
    text-indent: 0px;
    margin: 2px;
    letter-spacing: 0px;
    text-align: justify;
    font-weight: 550;
    font-family: inherit;
}

.agreement {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    align-items: flex-start;


}

.registration {

    align-items: center;
    align-self: center;
    flex-wrap: wrap;


}

.number {
    margin-right: 2px;
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
}

.adress {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: flex-end;
    text-align: end;
}