.description-ellipsis {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 50px;
    letter-spacing: 0.04em;
    line-height: 1.3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
}

.description-ellipsis1 {
    display: block;
    display: -webkit-box;
    max-width: 80%;
    height: 50px;
    letter-spacing: 0.04em;
    line-height: 1.3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
}